<template>
  <div v-if="preId"
       class="row mt-1 mb-1 mr-3 large-switch"
  >

    <b-form-checkbox v-model="internalValue"
                     size="lg"
                     name="check-button"
                     :disabled="disabled"
                     switch
    >
      <div :id="preId"
           class="pr-1"
      >
        {{ label }}
      </div>
    </b-form-checkbox>

    <b-tooltip ref="tooltip"
               variant="secondary"
               :target="'' + preId"
               placement="right"
               :delay="tooltipDelay"
    >
      {{ tooltip }}
    </b-tooltip>

  </div>
</template>

<script>

export default {
  name: "BaoCheckox",
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      default: null,
      required: false
    },
    index: {
      type: Number,
      default: 0,
      required: false
    },
    tooltipDelay: {
      type: Object,
      default () {
        return { show: 250, hide: 50 }
      },
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      internalValue: false,
      preId: null
    }
  },
  watch: {
    internalValue (newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal)
      }
    },
    value (val) {
      this.internalValue = val
    }
  },
  mounted () {
    this.internalValue = this.value
    // Note: this is not 100% guaranteed to return a unique ID but really close
    // (possible collision is 1 in 100k, but could be lower due to JS seeding for Math.random)
    // Also note: do not use milliseconds as the ONLY attribute for a unique ID, I tested it and depending on
    // the processors clock cycle one can get multiple IDs in a single CPU cycle -> multiple IDs with the same
    // value
    this.preId = Math.ceil(Math.random() * Math.random() * Date.now()) + ""
  }
}
</script>

<style scoped lang="scss">

</style>
