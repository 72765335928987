<template>
  <div class="list-container">
    <!-- Page Header Section -->
    <div class="d-flex justify-content-between">
      <h3 id="title-202012281236"
          class="title pb-4"
      >
        {{staticText.title}}
      </h3>
    </div>

    <div v-if="userIsAdmin"
         class="d-flex justify-content-between mb-3"
    >
      <b-button id="add-new-btn-202012281240"
                variant="primary"
                class="px-4 my-auto"
                @click="showModal()"
      >
        <i class="fas fa-plus"></i>
        {{staticText.addNewLabel}}
      </b-button>
    </div>

    <div class="table-wrapper">
      <div class="table-responsive">
        <b-table
          id="result-item-table-202012281312"
          :items="items"
          :fields="getFields()"
          sort-by="id"
          hover
          no-sort-reset
        >
          <template v-slot:cell(rating)="data">
            <div>
              <b-btn :id="'positive-result-' + data.item.id"
                     :variant="data.item.rating > 0 ? 'outline-success' : 'outline-secondary'"
                     disabled
              >
                <i class="fas fa-thumbs-up"></i>
              </b-btn>
            </div>
          </template>

          <template v-slot:cell(active)="data">
            <bao-checkox v-model="data.item.active"
                         @input="switchActive(data.item)"
            ></bao-checkox>
          </template>

          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-between">
              <b-btn :id="'edit-btn-' + data.item.id"
                     variant="secondary"
                     class="action-button mr-1"
                     @click="showModal(data.item)"
              >
                <img src="/img/icons/edit-icon.svg"/>
              </b-btn>

              <bao-delete-button
                :id="'result-delete-btn-202103180111-'+data.item.id"
                :url="'/api/resultitems/'+data.item.id"
                @deleted="getData"
              >
              </bao-delete-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <!-- Edit and Create dialog -->
    <b-modal id="create-new-result-item-202012281316"
             v-model="createDialogOpen"
             :title="staticText.modalTitle"
             :ok-title="staticText.modalOkBtnLabel"
             :cancel-title="staticText.modalCancelBtnLabel"
             size="lg"
             @ok.prevent="createNewResultItem"
    >
      <div v-if="createError">
        <b-alert variant="warning"
                 show
        >
          {{createError}}
        </b-alert>
      </div>

      <div class="container">
        <div class="row my-2">
          <span class="col-4 my-auto">
            {{staticText.textInputLabel}}:
          </span>

          <b-form-input v-model="text"
                        class="col"
                        type="text"
          ></b-form-input>
        </div>

        <div class="row my-2 mt-3">
          <span class="col-4 my-auto">
            {{staticText.ratingInputLabel}}:
          </span>

          <b-form-checkbox v-model="rating"
                           name="check-button"
                           class="col"
                           size="lg"
                           switch
          ></b-form-checkbox>
        </div>
      </div>
    </b-modal><!-- End of Edit and Create dialog -->
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import BaoCheckox from "../base/BaoCheckox"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"

export default {
  name: "ResultItemList",
  components: {
    BaoCheckox,
    BaoDeleteButton
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        title: "Result Item",
        addNewLabel: "Add new result item",
        textInputLabel: "Result Text",
        ratingInputLabel: "Is this a positive outcome of a conversation?",
        idLabel: "ID",
        textLabel: "Result Text",
        ratingLabel: "Positive result",
        activeLabel: "Active",
        actionLabel: "Action",
        editLabel: "Edit",
        createError: "Unable to create result item: result text missing.",
        modalOkBtnLabel: "Save",
        modalCancelBtnLabel: "Cancel",
        modalTitle: "Create a result item"
      },
      rating: false,
      text: "",
      createDialogOpen: false,
      saving: false,
      items: [],
      createError: null,
      itemId: null
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isUserAuthenticated: "auth/isUserAuthenticated"
    }),
    userIsAdmin () {
      return !!this.user && !!this.user.is_admin
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    switchRating (item, val) {
      return new Promise((resolve, reject) => {
        const data = { rating: val ? 1 : 0 }
        this.axios.patch("/api/resultitems/" + item.id, data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    switchActive (item) {
      return new Promise((resolve, reject) => {
        const data = { active: item.active }
        this.axios.patch("/api/resultitems/" + item.id, data).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    showModal (item = null) {
      this.createDialogOpen = true
      this.createError = null
      this.resetMappingData(item)
    },
    createNewResultItem () {
      if (!this.text) {
        this.createError = this.staticText.createError
        return Promise.resolve()
      }
      return new Promise((resolve, reject) => {
        const data = { text: this.text, rating: !this.rating ? 0 : 1, active: true }
        const isCreate = !this.itemId
        const method = isCreate ? "post" : "patch"
        let url = "/api/resultitems/"
        if (!isCreate) url += this.itemId
        this.axios[method](url, data).then(async () => {
          await this.getData()
          this.createDialogOpen = false
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    resetMappingData (item = null) {
      this.rating = !item ? true : item.rating > 0
      this.text = !item ? "" : item.text
      this.itemId = item ? item.id : null
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.axios.get("/api/resultitems").then(response => {
          this.items = response.data.results
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getFields () {
      const fields = [
        {
          key: "id",
          label: this.staticText.idLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "text",
          label: this.staticText.textLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "rating",
          label: this.staticText.ratingLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        }
      ]
      if (this.userIsAdmin) {
        fields.push(
          {
            key: "active",
            label: this.staticText.activeLabel,
            sortable: true,
            sortDirection: "desc",
            tdClass: "align-middle"
          },
          {
            key: "action",
            label: this.staticText.actionLabel,
            tdClass: "align-middle"
          }
        )
      }
      return fields
    }
  }
}
</script>

<style scoped lang="scss">
.action-button {
  border-radius: 12px !important;
  padding: 12px 14px;
}
</style>
