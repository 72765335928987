import { render, staticRenderFns } from "./BaoCheckox.vue?vue&type=template&id=0a07d89e&scoped=true&"
import script from "./BaoCheckox.vue?vue&type=script&lang=js&"
export * from "./BaoCheckox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a07d89e",
  null
  
)

export default component.exports